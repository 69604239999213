import add from 'lodash/add'
import round from 'lodash/round'
import subtract from 'lodash/subtract'

/**
 * 抄表的水量/本次水表行码的计算
 * 换表后，上次抄表行码会更新为新表的装表行码，因此计算的过程中不需要考虑换表场景
 */

function toNumber (value) {
  return value ? Number(value) : 0
}

const precision = 2 // 精度

// 水量的计算
export function quantityCalc (params) {
  const { maxnumber } = params
  const lastReadingNum = toNumber(params.lastReadingNum)
  const thisReadingNum = toNumber(params.thisReadingNum)
  /**
   * 常规情况下：水量=本次抄表-上次抄表
   * 存在量程并且水量小于0，进一步计算：水量=量程-上次抄表+本次抄表
   */
  let result = round(subtract(thisReadingNum, lastReadingNum), precision)
  if (maxnumber && result < 0) {
    const num1 = subtract(toNumber(maxnumber), lastReadingNum)
    result = round(add(num1, thisReadingNum), precision)
  }
  return result
}

// 本次抄表行码的计算
export function thisReadingNumCalc (params) {
  const { maxnumber } = params
  const lastReadingNum = toNumber(params.lastReadingNum)
  const quantity = toNumber(params.quantity)
  /**
   * 估抄情况下，本次抄表=水量+上次抄表
   * 存在量程并且本次抄表>量程，进一步计算：本次抄表=水量+上次抄表-量程
   */
  let result = round(add(quantity, lastReadingNum), precision)
  if (maxnumber && result > toNumber(maxnumber)) {
    result = round(subtract(result, toNumber(maxnumber)), precision)
  }
  return result
}
