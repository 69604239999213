<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/meterReading/list"
    size="medium"
    :filterFields="filterFields"
    :columns="columns"
    :defaultSortBy="[{prop: 'usewaterCode', order: 'asc'}]"
    :defaultFilterBy="defaultFilterBy"
    :cellClassNameFunc="readingStatusClassFunc"
    :operateButtonList="operateButtonList"
    :operationWidth="100")
  el-drawer.header-border(
    :title="`录入(${pageTip})`"
    size="40%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
      DataForm.form-box.full-box(
        v-if="drawer"
        ref="form"
        v-model="formData"
        buttonFixed
        buttonAlign="center"
        labelWidth="130px"
        :formFields="formFields"
        :buttonList="buttonList")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapActions } from 'vuex'
import { isObject, readingStatusClassFunc } from '@/utils/common.js'
import { quantityCalc, thisReadingNumCalc } from './calcFunc'
const required = [{ required: true, message: '必填' }]

export default {
  name: 'MeterReading',
  computed: {
    pageTip () {
      const length = this.records.sortIds.length
      return !length ? '' : `${this.curRecordIndex + 1}/${length}`
    },
    curRecordIndex () {
      return this.records.sortIds.indexOf(this.curRecordId)
    },
    preRecordIndex () {
      // 上一个
      const curIndex = this.curRecordIndex
      return curIndex !== -1 ? curIndex - 1 : -1
    },
    nextRecordIndex () {
      // 下一个
      const curIndex = this.curRecordIndex
      return curIndex !== -1 && curIndex + 1 < this.records.sortIds.length ? curIndex + 1 : -1
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'edit', url: '/meterRead/meterReading/edit' }
    ])
    return {
      showTable: false,
      readingStatusClassFunc,
      defaultFilterBy: {
        readingStatus: ['UNREAD', 'READED']
      },
      // 表格筛选表单配置
      filterFields: [{
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      },
      // {
      //   name: 'customerCode',
      //   label: '用户编号',
      //   form: {
      //     tag: 'input',
      //     like: true
      //   }
      // },
      // {
      //   name: 'customerName',
      //   label: '用户姓名',
      //   form: {
      //     tag: 'input',
      //     like: true
      //   }
      // },
      {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          multiple: true,
          collapseTags: true,
          clearable: true,
          filterable: true,
          relation: 'waterWorks'
        }
      }, {
        name: 'readingStatus',
        label: '抄表状态',
        form: {
          tag: 'select',
          relation: 'readingStatus',
          multiple: true
        }
      }],
      // 表格列配置
      columns: [
        { name: 'readingStatus', label: '抄表状态', relation: 'readingStatus', render: { type: 'select' } },
        { name: 'usewaterCode', label: '用水户号', minWidth: 120 },
        {
          name: 'customerName',
          label: '用户',
          minWidth: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'lastReadingNum', label: '上次抄表行码', minWidth: 120 },
        { name: 'thisReadingNum', label: '本次抄表行码', minWidth: 120 },
        { name: 'quantity', label: '水量' },
        { name: 'estimate', label: '是否估抄', render: { type: 'yesOrNo' } },
        { name: 'meterCondition', label: '表况', relation: 'meterStatus', render: { type: 'select' } },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 200 },
        { name: 'readingStaffName', label: '抄表员', minWidth: 100 },
        { name: 'meterNo', label: '水表编号' },
        { name: 'meterType', label: '水表类型', relation: 'meterType', render: { type: 'select' } },
        { name: 'orgName', label: '水厂', minWidth: 140 },
        { name: 'readingTime', label: '抄表日期', minWidth: 140, render: { type: 'date', timeType: 'YYYY-MM-DD' } },
        { name: 'createTime', label: '创建时间', minWidth: 120, render: { type: 'date' } },
        { name: 'updateTime', label: '更新日期', minWidth: 140, render: { type: 'date', timeType: 'YYYY-MM-DD' } }
      ],
      operateButtonList: [{
        label: '录入',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          this.getTotalUsewaterIdOptions(data.orgId)
          return this.getFullRecord().then(res => {
            this.initData(data)
            this.drawer = true
            return res
          })
        }
      }],
      // 侧拉表单是否展开
      drawer: false,
      // 表单数据
      initFormData: {},
      formData: {},
      formFields: [
        { name: 'readingStatus', label: '抄表状态', relation: 'readingStatus', form: { tag: 'text', dot: true }, render: { type: 'select' } },
        {
          name: 'usewaterCode',
          label: '用水户',
          form: { tag: 'text', dot: true },
          render: (value, field, data) => {
            const { customerName, usewaterCode } = data
            return `${customerName}(${usewaterCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址', form: { tag: 'text', dot: true } },
        {
          name: 'meterOutNo',
          label: '水表外码',
          form: { tag: 'text', dot: true }
        },
        {
          name: 'maxnumber',
          label: '量程',
          form: { tag: 'text', dot: true }
        },
        {
          name: 'totalSub',
          label: '总分表',
          form: { tag: 'text', dot: true },
          relation: 'totalSubType',
          options: [],
          totalUsewaterIdOptions: [],
          render: (value, field, data) => {
            if (!value) {
              return '非总分表'
            } else {
              const result = []
              const type = field.options.find(item => item.value === data.totalSubType) || {}
              result.push(type.label || '-')
              if (data.totalSubType === 'SUB') {
                const item = field.totalUsewaterIdOptions.find(item => item.value === data.totalUsewaterId) || {}
                result.push(item.label || '-')
              }
              return result.join('|')
            }
          }
        },
        {
          name: 'lastReadingNum',
          label: '上次抄表行码',
          form: { tag: 'text', dot: true },
          render: { type: 'padString', suffix: 'm³' }
        },
        {
          name: 'readingTime',
          label: '抄表日期',
          form: {
            tag: 'date',
            pickerOptions: {
              disabledDate (time) {
                return time.getTime() > Date.now()
              }
            }
          }
        },
        {
          name: 'thisReadingNum',
          label: '本次抄表行码',
          form: {
            tag: 'number',
            disabled: false,
            min: 0,
            chains: ({ chains, values, value }) => {
              const quantity = quantityCalc(values)
              chains.setValue('quantity', quantity)
            }
          }
        },
        {
          name: 'quantity',
          label: '水量',
          form: {
            tag: 'number',
            disabled: true,
            min: 0,
            chains: ({ chains, values, value }) => {
              const thisReadingNum = thisReadingNumCalc(values)
              chains.setValue('thisReadingNum', thisReadingNum)
            }
          }
        },
        {
          name: 'estimate',
          label: '估抄',
          form: {
            tag: 'switch',
            chains: ({ chains, value, values }) => {
              if (value) {
                const thisReadingNum = thisReadingNumCalc(values)
                chains.setValue('thisReadingNum', thisReadingNum)
              }
              chains.setState('quantity', (state) => {
                state.form.disabled = !value
              })
              chains.setState('thisReadingNum', (state) => {
                state.form.disabled = value
              })
              this.$refs.form.setRule('readingAttchIds', value ? required : [])
              this.$refs.form.$refs.dataform.clearValidate()
            }
          }
        },
        {
          name: 'meterCondition',
          label: '表况',
          inputWidth: '200px',
          form: {
            tag: 'select',
            relation: {
              name: 'meterStatusv2',
              filterBy: [{
                name: 'enable',
                type: 'eq',
                value: true
              }]
            },
            chains: ({ chains, value, values }) => {
              this.$refs.form.setRule('readingAttchIds', value !== 1 ? required : [])
              this.$refs.form.$refs.dataform.clearValidate()
            }
          }
        },
        {
          name: 'readingAttchIds',
          label: '附件',
          form: {
            tag: 'file',
            multiple: true,
            limit: 10,
            typeLimit: ['jpg', 'jpeg', 'png'],
            resource: '/wfbase/upload',
            resType: 'READING'
          }
        }
      ],
      buttonList: [{
        name: 'pageUp',
        label: '上一条',
        func: this.handlePageUp
      }, {
        name: 'submit',
        label: '提交',
        type: 'primary',
        validate: true,
        nativeType: 'submit',
        func: ({ formData }) => {
          return new Promise((resolve, reject) => {
            this.$patch({
              url: `/meterReading/updateReadingData/${formData.id}`,
              data: formData
            })
              .then(res => {
                if (!res) {
                  reject(res)
                  return
                }
                this.$message.success('录入成功,自动切换下一条')
                resolve('success')
                if (this.nextRecordIndex === -1) this.handleClose()
                else this.handlePageDown()
              })
              .catch(reject)
          })
        }
      }, {
        name: 'pageDown',
        label: '下一条',
        func: this.handlePageDown
      }],
      curRecordId: null,
      // 所有的抄表记录id与状态，用于切换上下条
      records: {
        sortIds: [], // 排序后的id
        status: {} // 当前状态，判断是已抄还是未抄
      }
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations', 'getDyncRelation']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    async renderRelation () {
      try {
        const { relationList, dyncList } = [...this.columns, ...this.formFields, ...this.filterFields]
          .reduce((res, item) => {
            const relation = item.relation || item?.form?.relation
            if (relation) {
              isObject(relation) ? res.dyncList.push(relation) : res.relationList.push(relation)
            }
            return res
          }, { relationList: [], dyncList: [] })
        // 获取字典
        const result = await this.getRelations(relationList)
        // 渲染
        this.$renderRelationColumns(result, [this.columns, this.formFields, this.filterFields])
        // 单独处理抄读状态
        this.filterFields[2].form.options = result.readingStatus.slice(0, 2)
        // 请求带参数的字典
        const dyncResult = await this.getDyncRelation(dyncList)
        this.$renderRelationColumns(dyncResult, [this.formFields])
        this.$nextTick(() => { this.showTable = true })
      } catch (e) {
        console.error(e)
        this.showTable = true
      }
    },
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      const estimate = !!this.formData.estimate
      this.formFields[8].form.disabled = estimate
      this.formFields[9].form.disabled = !estimate
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.initFormData = {}
      this.resetForm()
      this.getData()
    },
    // 初始化数据
    initData (data) {
      const cloneData = cloneDeep(data)
      // this.$set(this.formFields[6].form, 'min', cloneData.lastReadingNum)
      if (data.readingStatus === 'UNREAD') {
        // 未抄数据初始化
        cloneData.meterCondition = 1
        cloneData.readingTime = new Date().valueOf()
      }
      cloneData.readingAttchIds = cloneDeep(data.readingAttchs) // 附件
      cloneData.estimate = data.estimate || false // 估抄
      this.formFields.forEach((item, index) => {
        // 估抄时，本次抄表指读不可修改
        if (item.name === 'thisReadingNum') this.formFields[index].form.disabled = data.estimate
        else if (item.name === 'quantity') this.formFields[index].form.disabled = !data.estimate
      })
      const attachRules = cloneData.estimate || cloneData.meterCondition !== 1 ? required : []
      this.formFields[this.formFields.length - 1].form.rules = attachRules // 附件规则
      this.formData = cloneDeep(cloneData)
      this.initFormData = cloneData
      this.curRecordId = cloneData.id
    },
    // 获取抄读记录
    getRecordById (id) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/meterReading/get/${id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              this.initData(res.data)
              resolve(res.data)
            }
          })
          .catch(reject)
      })
    },
    // 获取所有抄表记录
    getFullRecord () {
      const filterBy = this.$refs.dataTable.currentFilterBy
      const params = cloneDeep(filterBy)
      params.readingStatus = filterBy.readingStatus.join(',')
      params.orgId = filterBy.orgId.join(',')
      if (!params.orgId) delete params.orgId
      return this.$get({
        url: 'meterReading/readingStatus',
        params
      })
        .then(res => {
          if (!res) return
          this.records = {
            sortIds: res.data.sort,
            status: res.data.data
          }
          return res.data
        })
    },
    // 上一页
    handlePageUp () {
      return new Promise((resolve, reject) => {
        if (this.preRecordIndex === -1) {
          this.$message.warning('已经是第一条了')
          reject(new Error('已经是第一条了'))
        } else {
          this.getRecordById(this.records.sortIds[this.preRecordIndex])
            .then(resolve).catch(reject)
        }
      })
    },
    // 下一页
    handlePageDown () {
      return new Promise((resolve, reject) => {
        if (this.nextRecordIndex === -1) {
          this.$message.warning('已经是最后一条了')
          reject(new Error('已经是最后一条了'))
        } else {
          this.getRecordById(this.records.sortIds[this.nextRecordIndex])
            .then(resolve).catch(reject)
        }
      })
    },
    // 获取总表数据
    getTotalUsewaterIdOptions (orgId) {
      return new Promise((resolve, reject) => {
        this.$get({ url: `/usewater/getCustomerTotalUsewater?orgId=${orgId}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            const index = this.formFields.findIndex(item => item.name === 'totalSub')
            this.formFields[index].totalUsewaterIdOptions = res.data ? res.data.map(item => {
              return { value: item.id, label: `${item.customerName}(${item.customerCode})` }
            }) : []
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding: 0 8px
  ::v-deep
    .wf-dataform__body
      margin-bottom: 71px
</style>
